<template>
  <div class="organizationBox">
    <div class="organizationInner">
      <el-row :gutter="25" class="organizationInnerPc">
        <el-col :span="8">
          <ul>
            <li v-for="(item, index) in list[0]" :key="index">
              <a :href="item.httpUrl" target="_blank" :title="item.name">{{ item.name }}</a>
            </li>
          </ul>
        </el-col>
        <el-col :span="8">
          <ul>
            <li v-for="(item, index) in list[1]" :key="index">
              <a :href="item.httpUrl" target="_blank" :title="item.name">{{ item.name }}</a>
            </li>
          </ul>
        </el-col>
        <el-col :span="8">
          <ul>
            <li v-for="(item, index) in list[2]" :key="index">
              <a :href="item.httpUrl" target="_blank" :title="item.name">{{ item.name }}</a>
            </li>
          </ul>
        </el-col>
      </el-row>
      <el-row :gutter="8" class="organizationInnerMobile">
        <el-col :span="12" v-for="(item, index) in listMobile" :key="index">
          <ul>
            <li>
              <a :href="item.httpUrl" target="_blank" :title="item.name">{{ item.name }}</a>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { getApplicationList } from '@/api/common'
export default {
  data() {
    return {
      list01: [
        {
          entTOrganizationUuid: '',
          httpUrl: '',
          name: '即可进入高科技',
          organizationId: 0
        },
        {
          entTOrganizationUuid: '',
          httpUrl: '',
          name: '即可进入高科技',
          organizationId: 0
        }
      ],
      list02: [],
      list03: [],
      list: [],
      listMobile: []
    }
  },
  mounted() {
    var _this = this

    _this.getApplicationList()
  },
  methods: {
    getApplicationList() {
      var _this = this
      getApplicationList().then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          // _this.list = data
          _this.listMobile = data
          var listIndex = Math.ceil(data.length / 3)
          _this.list = _this.group(data, listIndex)
          // console.log('list :>> ', _this.list)
        }
      })
    },
    group(array, subGroupLength) {
      let index = 0
      let newArray = []
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)))
      }
      return newArray
    }
  }
}
</script>
<style lang="scss" scoped>
.organizationInner {
  text-align: center;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      list-style: none;
      a {
        display: block;
        overflow: hidden;
        height: 40px;
        line-height: 40px;
        margin: 20px 0;
        border: 1px #a4b3c6 solid;
        background-color: #ebeff2;
        color: #093d77;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
      }
      a:hover {
        background-color: #f3faff;
      }
    }
  }
}
.organizationInnerMobile {
  display: none;
}
@media (max-width: 992px) {
  .organizationInner ul li a {
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .organizationInner ul li a {
    font-size: 10px;
    height: 32px;
    line-height: 32px;
    margin: 10px 0;
  }
  .organizationInnerPc {
    display: none;
  }
  .organizationInnerMobile {
    display: block;
  }
}
</style>
