<template>
  <div class="organizationBox">
    <el-row :gutter="20" class="organizationOutPc">
      <el-col :span="8" v-for="(item, index) in list" :key="index">
        <div class="organizationInner" v-if="index !== 10">
          <div class="organizationTitle">{{ item.title }}</div>
          <div class="organizationGrop">
            <div class="organizationDes" v-if="item.des">{{ item.des }}</div>
            <div class="organizationEmail" v-if="item.address">地址：{{ item.address }}</div>
            <div class="organizationEmail" v-if="item.email">邮箱：{{ item.email }}</div>
            <div class="organizationEmail" v-if="item.tel">电话：{{ item.tel }}</div>
            <div class="organizationEmail" v-if="item.fax">传真：{{ item.fax }}</div>
            <div class="organizationEmail" v-if="item.url">网络主页：{{ item.url }}</div>
          </div>
        </div>
        <div class="organizationInner" v-if="index == 10">
          <div class="organizationTitle">{{ item.title }}</div>
          <div class="organizationGrop">
            <div class="organizationDes">伦敦：</div>
            <div class="organizationEmail">地址：伦敦芬奇利路124号瑞金娜大厦</div>
            <div class="organizationEmail">REGINA HOUSE 124 FINCHLEY ROAD LONDON UNITED KINGDOM NW3 5JS</div>
            <div class="organizationEmail">电话：0044-7467093787</div>
            <div class="organizationDes">伦敦：</div>
            <div class="organizationEmail">地址：非洲加纳共和国特马港阿夏马木材市场</div>
            <div class="organizationEmail">P.O.Box 10128，Accra-North，Ghana Behind Ashaiman Timber Market，Tema</div>
            <div class="organizationEmail">电话：0044-7467093787</div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="10" class="organizationOutMobile">
      <el-col :span="24" v-for="(item, index) in list" :key="index">
        <div class="organizationInner" v-if="index !== 10">
          <div class="organizationTitle">{{ item.title }}</div>
          <div class="organizationGrop">
            <div class="organizationDes" v-if="item.des">{{ item.des }}</div>
            <div class="organizationEmail" v-if="item.address">地址：{{ item.address }}</div>
            <div class="organizationEmail" v-if="item.email">邮箱：{{ item.email }}</div>
            <div class="organizationEmail" v-if="item.tel">电话：{{ item.tel }}</div>
            <div class="organizationEmail" v-if="item.fax">传真：{{ item.fax }}</div>
            <div class="organizationEmail" v-if="item.url">网络主页：{{ item.url }}</div>
          </div>
        </div>
        <div class="organizationInner" v-if="index == 10">
          <div class="organizationTitle">{{ item.title }}</div>
          <div class="organizationGrop">
            <div class="organizationDes">伦敦：</div>
            <div class="organizationEmail">地址：伦敦芬奇利路124号瑞金娜大厦</div>
            <div class="organizationEmail">REGINA HOUSE 124 FINCHLEY ROAD LONDON UNITED KINGDOM NW3 5JS</div>
            <div class="organizationEmail">电话：0044-7467093787</div>
            <div class="organizationDes">伦敦：</div>
            <div class="organizationEmail">地址：非洲加纳共和国特马港阿夏马木材市场</div>
            <div class="organizationEmail">P.O.Box 10128，Accra-North，Ghana Behind Ashaiman Timber Market，Tema</div>
            <div class="organizationEmail">电话：0044-7467093787</div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          title: '中甘国际加纳集团',
          des:
            '公司发展成以工业与民用建筑、市政路桥建设和国际工程承包为主业，涉足房地产开发、建材销售、石料生产与销售、进出口贸易、药品生产、酒店经营管理及旅游管理等多元业务领域的综合性国有企业。拥有9个全资和控股子公司。',
          email: 'ghana@cgicop.com/cshlcghgroup@sina.com'
        },
        {
          title: '中甘国际安哥拉公司',
          address: '于罗安达KK新城情报局培训学院院内',
          email: 'govec.angola.ltd@gmail.com'
        },
        {
          title: '中甘国际巴基斯坦公司',
          address: '巴基斯坦伊斯兰堡蓝区真纳大道F区马利克办公楼3楼11号',
          email: 'cgicoppkad@163.com'
        },
        {
          title: '中甘国际印度尼西亚公司',
          des: '公司位于印度尼西亚，总部设于Maspion Plaza, Lt. 3-i, Jl. Gunung Sahari Kav. 18, North Jakarta, 14420，',
          tel: '62 21 29378908',
          fax: '62 21 29378908',
          url: 'www.cgicop.com',
          email: 'indo@cgicop.com'
        },
        {
          title: '中甘国际津巴布韦公司',
          des: '下设华陇津巴布韦建筑公司，华陇国际旅行社，Solid West房地产开发公司，Lakedale农业开发公司。公司总部设在首都哈拉雷。',
          email: 'hualongzimbabwe@gmail.com'
        },
        {
          title: '中甘国际马拉维公司',
          address: '马拉维利隆圭市28区',
          email: 'hualongmalawi@gmail.com'
        },
        {
          title: '中甘国际莫桑比克公司',
          address: '中甘国际莫桑比克公司',
          email: 'gshlovec@gmai.com'
        },
        {
          title: '中甘国际肯尼亚公司',
          address: '尼亚内罗毕市 拉文顿区曼娅妮东路121号',
          email: 'cgicopkenya@gmail.com'
        },
        {
          title: '中甘国际白俄罗斯公司',
          address: '白俄罗斯，明斯克市，S.柯瓦列夫斯卡娅小巷，48号',
          email: 'cgicop-bel@hotmail.com'
        },
        {
          title: '中甘国际沙特公司',
          address: '沙特阿拉伯利雅得市穆如吉区阿拉街1号楼',
          email: 'saudi@cgicop.com'
        },
        {
          title: '格赛克国际技术有限公司',
          address: '',
          des: '',
          tel: '',
          url: '',
          email: ''
        }
      ]
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.organizationInner {
  height: 320px;
  overflow: hidden;
  margin: 10px 0;
  padding: 10px;
  box-shadow: 0px 1px 4px #b2a3a3;
  background-color: #f2f2f2;
  line-height: 24px;
  .organizationTitle {
    font-size: 18px;
    text-align: left;
    margin: 10px 0px;
    color: #0263b2;
  }
  .organizationGrop {
    font-size: 14px;
    color: #333;
  }
}
.organizationOutMobile {
  display: none;
}
@media (max-width: 992px) {
  .organizationInner {
    line-height: 20px;
  }
  .organizationInner .organizationTitle {
    margin: 0px 0px 8px;
  }
}
@media (max-width: 768px) {
  .organizationInner {
    height: auto;
    margin: 6px 0;
  }
  .organizationOutPc {
    display: none;
  }
  .organizationOutMobile {
    display: block;
  }
}
</style>
