<template>
  <div class="organizationBox">
    <img src="../../../../assets/image/NLdbz_2.jpg" alt="">
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  },
  mounted() {

  },
  methods: {

  }
}
</script>
<style lang="scss" scoped>
.organizationBox {
  text-align: center;
  img {
    max-width: 100%;
  }
}
</style>
