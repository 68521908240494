<template>
  <div class="gropProfile">
    <div class="ziHead">
      <img :src="indexImg" style="width: 100%; height: 100%" />
    </div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainLeft">
          <span
            class="navMainInner"
            :class="{ active: active == item.entTChannelUuid }"
            v-for="(item, index) in navSubList"
            :key="index"
            @click="handleTab(item, index)"
          >
            {{ item.name }}
          </span>
        </div>

        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <a href="/groupProfile/00180001" class="textTitle">{{ mathedTitle }} </a>
          <span> &nbsp;> 组织机构 </span>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="ziContact">
        <div class="rowContact">
          <div class="organizationTitle">组织机构</div>
          <div class="organizationHd">
            <span
              v-for="(item, index) in organizationHdList"
              :class="{ active: activeHd == index }"
              :key="index"
              @mouseenter="mouseenterHd(index)"
              >{{ item.name }}</span
            >
          </div>
          <div class="organizationWrap">
            <inside v-if="activeHd == 0" ref="inside"></inside>
            <subcompany v-if="activeHd == 1" ref="inside"></subcompany>
            <outside v-if="activeHd == 2" ref="inside"></outside>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getChildChannel } from '@/api/common'
import inside from './inside.vue'
import subcompany from './subcompany.vue'
import outside from './outside.vue'
export default {
  components: {
    inside,
    subcompany,
    outside
  },
  data() {
    return {
      listId: '',
      isDeail: false,
      isList: false,
      active: '',
      indexImg: '',
      fullpath: '/groupProfile/companyProfile',
      pathtitle: '',
      mathedTitle: '集团概况',
      navSubList: [],
      organizationHdList: [
        {
          name: '内部机构'
        },
        {
          name: '子公司'
        },
        {
          name: '驻外机构'
        }
      ],
      activeHd: 1
    }
  },
  computed: {},
  mounted() {
    var _this = this

    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    _this.getSubGrop()
  },
  methods: {
    // 获取二级栏目
    getSubGrop() {
      var _this = this
      let data = {
        applicationUuid: _this.webId,
        parentUuid: '0018'
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.navSubList = data
          data.length &&
            data.forEach(line => {
              if (line.entTChannelUuid == _this.listId) {
                _this.pathtitle = line.name
                _this.indexImg = line.fileName
                if (line.catalog == 1) {
                  this.isDeail = true
                } else {
                  this.isList = true
                }
              }
            })
        }
      })
    },
    handleTab(item) {
      this.isDeail = false
      this.isList = false
      this.$router.push(`/groupProfile/${item.entTChannelUuid}`)
      this.pathtitle = item.name
      this.active = item.entTChannelUuid
      this.listId = item.entTChannelUuid
      this.indexImg = item.fileName
      this.$nextTick(() => {
        if (item.catalog == 1) {
          this.isDeail = true
        } else {
          this.isList = true
        }
      })
    },
    mouseenterHd(val) {
      var _this = this

      _this.activeHd = val
    }
  },
  watch: {
    $route(val) {
      this.fullpath = val.fullPath
      // this.pathtitle = val.meta.title
      // this.mathedTitle = val.matched[val.matched.length - 2].meta.title
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}

.gropProfile {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.gropProfile .ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  // background: url(../../../../assets/image/groupProfile0.jpg) center no-repeat;
  background-size: 100% 100%;
}

.gropProfile .ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.gropProfile .navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}
.gropProfile .navMainLeft {
  width: 900px;
  float: left;
}
.gropProfile .navMainLeft a {
  height: 42px;
  line-height: 42px;
  display: inline-block;
  margin: 0px 5px;
  padding: 0px 10px;
  float: left;
}
.gropProfile .navMainLeft a:hover {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.gropProfile .navMainLeft a.active {
  color: #0263b2;
  border-bottom: 2px #0263b2 solid;
}
.gropProfile .navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 15px;
}
.gropProfile .navMainRight span {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}

.gropProfile .navMainRight .textTitle {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.gropProfile .ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.gropProfile .ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.gropProfile .rowContact {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
  box-sizing: border-box;
  // background-color: rgb(206, 30, 30);
  margin-bottom: 20px;
}
.navMainInner {
  display: inline-block;
  padding: 0 10px;
  cursor: pointer;
  height: 42px;
  line-height: 43px;
  box-sizing: border-box;
  font-size: 17px;
}
.navMainInner.active {
  border-bottom: 2px solid #0263b2;
}
.organizationTitle {
  line-height: 60px;
  text-align: center;
  font-size: 24px;
}
.organizationHd {
  text-align: center;
  margin: 20px 0;
}
.organizationHd span {
  display: inline-block;
  width: 248px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  background-color: #ebf1f5;
  cursor: pointer;
}
.organizationHd span.active {
  background-color: #0162b3;
  color: #ffffff;
}
@media (max-width: 1300px) {
  .gropProfile .navMain,
  .companyProfile,
  .gropProfile .rowContact {
    width: 1000px;
  }
  .gropProfile .navMainLeft,
  .gropProfile .navMainRight {
    width: auto;
  }
}
@media (max-width: 992px) {
  .gropProfile .navMain,
  .companyProfile,
  .gropProfile .rowContact {
    width: 96%;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .gropProfile .ziHead {
    height: 362px;
  }
  .organizationHd span {
    height: 50px;
    line-height: 50px;
  }
  .gropProfile .rowContact {
    padding: 20px 10px;
  }
}
@media (max-width: 768px) {
  .gropProfile .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .gropProfile .navMainRight {
    display: none;
  }
  .gropProfile .ziHead {
    height: 200px;
  }
  .gropProfile .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
  .organizationHd span {
    width: 33.33%;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }
  .organizationTitle {
    line-height: 40px;
    font-size: 22px;
  }
  .organizationHd {
    margin: 10px 0;
  }
}
</style>
